/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
  fill: #3F0B3A;
}

/*
 * Github color scale
 */


.react-calendar-heatmap .color-github-1 {
  fill: #f9f8fd;
}
.react-calendar-heatmap .color-github-2 {
  fill: #ece8f9;
}
.react-calendar-heatmap .color-github-3 {
  fill: #dfd8f6;
}
.react-calendar-heatmap .color-github-4 {
  fill: #d3c9f2;
}
.react-calendar-heatmap .color-github-5 {
  fill: #c6b9ee;
}
.react-calendar-heatmap .color-github-6 {
  fill: #b9a9ea;
}
.react-calendar-heatmap .color-github-7 {
  fill: #ac99e6;
}
.react-calendar-heatmap .color-github-8 {
  fill: #9f89e2;
}
.react-calendar-heatmap .color-github-9 {
  fill: #927adf;
}
.react-calendar-heatmap .color-github-10 {
  fill: #856adb;
}
.react-calendar-heatmap .color-github-11 {
  fill: #856adb;
}
.react-calendar-heatmap .color-github-12 {
  fill: #795ad7;
}
.react-calendar-heatmap .color-github-13 {
  fill: #6c4ad3;
}
.react-calendar-heatmap .color-github-14 {
  fill: #5f3bcf;
}
.react-calendar-heatmap .color-github-15 {
  fill: #5530c6;
}
.react-calendar-heatmap .color-github-16 {
  fill: #4e2cb6;
}
.react-calendar-heatmap .color-github-17 {
  fill: #4729a6;
}
.react-calendar-heatmap .color-github-18 {
  fill: #412597;
}
.react-calendar-heatmap .color-github-19 {
  fill: #3a2187;
}
.react-calendar-heatmap .color-github-20 {
  fill: #331d77;
}
.react-calendar-heatmap .color-github-21 {
  fill: #2c1967;
}
.react-calendar-heatmap .color-github-22 {
  fill: #251557;
}
.react-calendar-heatmap .color-github-23 {
  fill: #1f1148;
}
.react-calendar-heatmap .color-github-24 {
  fill: #180e38;
}
.react-calendar-heatmap .color-github-25 {
  fill: #110a28;
}
.react-calendar-heatmap .color-github-26 {
  fill: #0a0618;
}
.react-calendar-heatmap .color-github-27 {
  fill: #040209;
}

/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}
